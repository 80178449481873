.search-audicode {
	display: block;
	min-height: 280px;
	overflow: hidden;
}

.search-audicode__top {
	padding-top: var(--space-l);
}

.search-audicode__bottom {
	padding-bottom: var(--space-xl);
}

.search-audicode__top,
.search-audicode__middle,
.search-audicode__bottom {
	position: relative;
}

.search-audicode__background-color-container {
	background-color: var(--color-grey-05);
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 0;
}

.search-audicode__background-color-container--size60 {
	bottom: 40%;
}

.search-audicode__text-container {
	box-sizing: border-box;
	margin: 0 auto;
	max-width: 576px;
	padding-left: 4%;
	padding-right: 4%;
	position: relative;
	z-index: 1;
}

.search-audicode__vtp-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xs);
	margin-top: var(--space-s);
}

.search-audicode__carline {
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-s);
}

.search-audicode__engine {
	margin-top: var(--space-s);
	position: relative;
	z-index: 1;
}

.search-audicode__price {
	font-weight: var(--font-weight-bold);
	margin-top: var(--space-xs);
	position: relative;
	z-index: 1;
}

.search-audicode__image-container {
	margin: calc(-24px + -6px) -5vw 0;
	position: relative;
	width: 110vw;
	z-index: 0;
}

.search-audicode__image-container--vtp {
	margin-top: 0;
}

.search-audicode__image {
	width: 100%;
}

.search-audicode__link {
	position: relative;
	z-index: 1;
}

.search-audicode__reference {
	margin-top: var(--space-xl);
}

.search-audicode__reference-label {
	font-weight: var(--font-weight-bold);
}

.search-audicode__reference-label,
.search-audicode__reference-text {
	display: inline;
}

.search-audicode__disclaimer {
	margin-top: var(--space-xxs);
}

.search-audicode__disclaimer:first-of-type {
	margin-top: var(--space-s);
}

.search-audicode__disclaimer-label,
.search-audicode__disclaimer-text,
.search-audicode__disclaimer-value {
	display: inline;
}

.search-audicode__disclaimer-value {
	padding-left: var(--space-xs);
}

.search-audicode__error {
	color: var(--color-red);
}

.search-audicode__error-pdflink {
	margin-top: var(--space-m);
}

@media all and (min-width: 576px) {
	.search-audicode__image-container {
		margin-left: auto;
		margin-right: auto;
		width: 576px;
	}
}

@media all and (min-width: 1024px) {
	.search-audicode__image-container {
		margin-bottom: -24px;
		margin-top: calc(-24px - 6px - 18px);
	}

	.search-audicode__image-container--vtp {
		margin-top: -24px;
	}

	.search-audicode__text-container {
		padding-left: calc(var(--space-l) + var(--space-s));
		padding-right: calc(var(--space-l) + var(--space-s));
		width: 576px;
	}
}
